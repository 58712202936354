import moment from "moment";
import firebase from 'firebase'
import Swal from "sweetalert2";


export const useUtilitarios = () => {

  const atualizarStatusFiliacao = async (idFederacao, usuarios) => {

    const mensagemLoading = (msg) => {
      Swal.fire({
        title: 'Aguarde',
        html: msg,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
      })
    }

    mensagemLoading('carregando dados...')

    // Atualizar o status da filiação quando passado do vencimeto

    const aprovado = 2;
    const vencido = 4;
    const filiacaoPaga = '2'
    const anuidade = 2
    const aposUmAno = '1';
    const aoFimDoAno = '2';

    const firebaseCollectionsRefferences = {
      federacoes: firebase.firestore().collection('federacoes'),
      filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes')
    }

    let federacao = {}

    const snapFederacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).get()

    if (snapFederacao.exists) {
      federacao = snapFederacao.data()
    }

    if ([aposUmAno, aoFimDoAno].includes(federacao?.regrasFiliacao?.vencimentoAnuidade)) {

      let usuariosAlterados = 0

      for (let usuario of usuarios) {

        if (usuario?.filiacoes?.length > 0) {
          if (usuario?.filiacoes.includes(idFederacao)) {

            const snapFiliacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

            if (!snapFiliacao.empty) {
              const filiacao = snapFiliacao.docs.map(d => d.data())[0];

              if (filiacao?.statusFiliacao === aprovado) {

 
                  if (filiacao?.statusFiliacao === aprovado) {

                    console.log('filiacaoVencida(filiacao?.dtAprovado: ', filiacaoVencida(filiacao?.dtAprovado, federacao?.regrasFiliacao?.vencimentoAnuidade));
                    

                    if (filiacaoVencida(filiacao?.dtAprovado, federacao?.regrasFiliacao?.vencimentoAnuidade)) {

                      let dados = {
                        statusFiliacao: vencido,
                        statusImagemFoto: vencido,
                        statusImagemRg: vencido,
                        dtRgAprovado: null,
                        dtFotoAprovado: null
                      }

                      if (filiacao.opcaoEntregaSelecionado) {
                        dados = {
                          ...dados,
                          opcaoEntregaSelecionado: ''
                        }
                      }

                      if (filiacao.adquirirCarteirinhaFisica) {
                        dados = {
                          ...dados,
                          adquirirCarteirinhaFisica: ''
                        }
                      }
                      
                      if (filiacao.enderecoEntregaCarteirinha) {
                        dados = {
                          ...dados,
                          enderecoEntregaCarteirinha: null
                        }
                      }

                      if (filiacao.dadosCarteirinha) {
                        dados = {
                          ...dados,
                          dadosCarteirinha: null
                        }
                      }

                      if (filiacao.adquirirCarteirinhaDigital) {
                        dados = {
                          ...dados,
                          adquirirCarteirinhaDigital: ''
                        }
                      }
                      if (filiacao.habilitarCarteirinhaDigital) {
                        dados = {
                          ...dados,
                          habilitarCarteirinhaDigital: ''
                        }
                      }
                      if (filiacao.dtAprovado) {
                        dados = {
                          ...dados,
                          dtAprovado: null
                        }
                      }

                      await firebaseCollectionsRefferences.filiacoes.doc(filiacao.id).update(dados)

                      usuariosAlterados++;

                    }
                  }
                

              }

            }

          }
        }

      };

      // se houver altum usuário alterado, atualizar a página para que a informação seja atualizada.

      if (usuariosAlterados > 0) {
        window.location.reload()
      }

    }

  Swal.close()

  }

  const filiacaoVencida = (dtAprovado, tipoVencimento) =>{

    const converterData = (data) => {
      return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD HH:mm')
    }

    const aposUmAno = '1';
    const aoFimDoAno = '2';

    if(tipoVencimento == aposUmAno){
      const diasPassados = moment().diff(moment(converterData(dtAprovado)), 'days')
      return (diasPassados > 365);
    }

    if(tipoVencimento == aoFimDoAno){

      const _dtAprovado = converterData(dtAprovado);
      const anoFiliacao = new Date(_dtAprovado).getFullYear();
      const diasParaOFimDoAno = moment(`${anoFiliacao}-12-31 23:59`).diff(moment(), 'days')

      console.log('anoFiliacao: ', anoFiliacao);
      console.log('_dtAprovado: ', _dtAprovado);
      
      console.log('moment(converterData(`31-12-${anoFiliacao} 23:59`)): ', moment(converterData(`31-12-${anoFiliacao} 23:59`)));
      

      console.log('diasParaOFimDoAno: ', diasParaOFimDoAno);
      

      return (diasParaOFimDoAno < 0);
    }

    return false;

  }

  const verificaCampoDisponivel = async (item, comparacao, collectionRefference) => {

    // validar se um dado campo (ex: CPF ou Email) está disponível ou já existe cadastrado.

    try {
      const snap = await collectionRefference.where(item, '==', comparacao).get();
      if (snap.empty) {
        return true
      } else if (!snap.empty) {
        return false
      }
    } catch (error) {
      console.log(error);
    }
  }

  return { atualizarStatusFiliacao, verificaCampoDisponivel }
}