import { useState, useEffect, useRef } from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import Swal from 'sweetalert2'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


export default function Carteirinha() {


  const queryParams = new URLSearchParams(window.location.search);
  const idFederacao = queryParams.get('fd')
  const idFiliacao = queryParams.get('fl')
  const tipoCarteirinha = queryParams.get('tp') ? queryParams.get('tp') : 'fis';

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const [federacao, setFederacao] = useState({})
  const [filiacao, setFiliacao] = useState({});
  const [carteirinhas, setCarteirinhas] = useState([])
  console.log('carteirinhas: ', carteirinhas);

  const [carteirinhaReprovada, setCarteirinhaReprovada] = useState(null);

  console.log('carteirinhaReprovada: ', carteirinhaReprovada);


  const [usuario, setUsuario] = useState({})




  const [fotoRg, setFotoRg] = useState('Nenhum arquivo selecionado');
  const [arquivoRg, setArquivoRg] = useState('');
  const refRgFile = useRef(null);
  const [uploadArquivoRg, setUploadArquivoRg] = useState('');

  const [fotoDocumento, setFotoDocumento] = useState('Nenhum arquivo selecionado');
  const [arquivoFoto, setArquivoFoto] = useState('');
  const refDocumentoFile = useRef(null);
  const [uploadArquivoFoto, setUploadArquivoFoto] = useState('');

  const [fotoComprovante, setFotoComprovante] = useState('Nenhum arquivo selecionado');
  const [arquivoComprovante, setArquivoComprovante] = useState('');
  const [uploadArquivoComprovante, setUploadArquivoComprovante] = useState('');
  const refComprovanteFile = useRef(null);
  const [pagamentoPendente, setPagamentoPendente] = useState({})
  const [pagamentosAprovados, setPagamentosAprovados] = useState([])
  const [pagamentos, setPagamentos] = useState([])

  const [concordoTermo, setConcordoTermo] = useState(false);


  const [novaCarteirinha, setNovaCarteirinha] = useState(false);
  const [adquirirCarteirinhaFisica, setAdquirirCarteirinhaFisica] = useState('')


  const [progresso, setProgresso] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)
  const [opcaoEntregaSelecionado, setOpcaoEntregaSelecionado] = useState('')

  // Endereço de Entrega da Carteirinha

  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [cep, setCep] = useState('');


  const UFs = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MS', 'MT', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
  ]


  const graduacoes = ["BRANCA", "CINZA", "AMARELA", "LARANJA", "VERDE", "AZUL", "ROXA", "MARROM", "PRETA", "CORAL"]

  const mediaQuery = window.matchMedia('(max-width: 650px)');



  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            setUsuario(doc.data())

            servicos.filiacoes.doc(idFiliacao).get().then(snap => {
              if (snap.exists) {
                setFiliacao(snap.data())
              }
            })

            const tipo = tipoCarteirinha === 'dig' ? 'digital' : 'fisica'
            servicos.filiacoes.doc(idFiliacao).collection('carteirinhas')
              .where('tipoCarteirinha', '==', tipo)
              .get().then(snap => {
                if (!snap.empty) {
                  const data = snap.docs.map(d => d.data())
                  setCarteirinhas(data);
                }
              })


          }

        })

      } else {
        window.location = '/'
      }

    })

    // carregar Federação, Modalidade e Graduações

    servicos.federacoes.doc(idFederacao).get().then(snap => {

      const _federacao = snap.data()
      setFederacao(_federacao);

      if(tipoCarteirinha === 'dig'){
        if(!_federacao?.carteirinha?.carteirinhaDigital === '2'){
          window.location = '/';
        }
      }
      
    })



  }, [])

  useEffect(() => {
    if (filiacao.id) {
      servicos.filiacoes.doc(filiacao.id).collection('pagamentos').get().then(snap => {
        if (!snap.empty) {
          const data = snap.docs.map(d => d.data())
          if (data.some(pgto => pgto.status !== 2)) {
            const _pagamento = data.find(pgto => pgto.status !== 2)
            setPagamentoPendente(_pagamento)
            setFotoComprovante(_pagamento.nomeImagemComprovantePgto)
          }
          if (data.some(pgto => pgto.status == 2)) {
            const _pagamentos = data.filter(pgto => pgto.status == 2)
            setPagamentosAprovados(_pagamentos)
          }
          setPagamentos(data)
        }

      })

    } else {
      setPagamentoPendente({})
      setFotoComprovante('Nenhum arquivo selecionado')
    }

  }, [filiacao])

  useEffect(() => {

    if (carteirinhas.length > 0) {
      const existeCarteirinhaReprovada = carteirinhas.some(crt => crt.status === 3);

      if (existeCarteirinhaReprovada) {
        const _carteirinhaReprovada = carteirinhas.find(crt => crt.status === 3);
        setCarteirinhaReprovada(_carteirinhaReprovada);
        setNovaCarteirinha(true);
        setOpcaoEntregaSelecionado(_carteirinhaReprovada?.opcaoEntregaSelecionado ? _carteirinhaReprovada?.opcaoEntregaSelecionado : '')


        if (_carteirinhaReprovada?.enderecoEntregaCarteirinha) {
          const endrc = _carteirinhaReprovada?.enderecoEntregaCarteirinha;
          setEndereco(endrc?.endereco ? endrc.endereco : '');
          setNumero(endrc?.numero ? endrc.numero : '');
          setComplemento(endrc?.complemento ? endrc.complemento : '');
          setBairro(endrc?.bairro ? endrc.bairro : '');
          setCidade(endrc?.cidade ? endrc.cidade : '');
          setUf(endrc?.uf ? endrc.uf : '');
          setCep(endrc?.cep ? endrc.cep : '');
        }



      }
    }

  }, [carteirinhas])


  const handleFotoComprovante = (event) => {
    event.stopPropagation()
    setArquivoComprovante(event.target.files[0])
    if (uploadArquivoComprovante) {
      URL.revokeObjectURL(uploadArquivoComprovante)
    }
    setUploadArquivoComprovante(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoComprovante(file ? file.name : 'Nenhum arquivo selecionado');

  };


  function mensagemAtencao(msg) {
    Swal.fire('Atenção', msg, 'warning')
  }

  function mensagemEndereco(carteinha) {

    let texto = '';
    const enviarAoFiliado = '2';
    const retiradaNaFederacao = '1';

    if (
      carteinha?.opcaoEntregaSelecionado == enviarAoFiliado ||
      federacao?.opcaoEntrega == enviarAoFiliado
    ) {

      if (carteinha?.opcaoEntregaSelecionado == enviarAoFiliado) {
        texto = 'Opção de entrega escolhido: <b>Enviar ao Filiado</b><br/>'
      }
      texto += `
      <b>Endereço:</b> ${carteinha?.enderecoEntregaCarteirinha?.endereco}
      <b>Numero:</b> ${carteinha?.enderecoEntregaCarteirinha?.numero}
      <b>Complemento:</b> ${carteinha?.enderecoEntregaCarteirinha?.complemento ? carteinha?.enderecoEntregaCarteirinha?.complemento : '--'}
      <b>Bairro:</b> ${carteinha?.enderecoEntregaCarteirinha?.bairro}
      <b>Cidade:</b> ${carteinha?.enderecoEntregaCarteirinha?.cidade}
      <b>UF:</b> ${carteinha?.enderecoEntregaCarteirinha?.uf}
      <b>CEP:</b> ${carteinha?.enderecoEntregaCarteirinha?.cep}
      `
    } else if (
      carteinha?.opcaoEntregaSelecionado == retiradaNaFederacao ||
      federacao?.opcaoEntrega == retiradaNaFederacao
    ) {
      if (carteinha?.opcaoEntregaSelecionado == retiradaNaFederacao) {
        texto = 'Opção de entrega escolhido: <b>Retirar na Federação</b><br/>'
      }
      texto += federacao?.carteirinha?.enderecoRetiradaCarteirinha
    }


    Swal.fire({
      title: 'Endereço / Local de Entrega da carteirinha',
      html: texto,
      icon: 'info',
      customClass: {
        content: 'swal-message'
      }
    })
  }

  function mensagemAlerta(msg) {
    Swal.fire('Alerta', msg, 'warning')
  }
  function mensagemReprovado(msg) {
    Swal.fire('Solicitação Reprovada', msg, 'warning')
  }

  function mensagemObservacao(status, msg) {

    switch (status) {
      case 1:
        Swal.fire('Solicitação Pendente', 'Aguarde. A solicitção da carteirinha será processada em breve. ', 'info'); break;
      case 2:
        Swal.fire('Em Produção', 'A carteirinha já está sendo confeccionada e ficará pronta em breve. ', 'info'); break;
      case 3:
        Swal.fire('Solicitação Reprovada', msg, 'warning'); break;
      case 4:
        Swal.fire('Retirada Disponível', 'A carteirinha já pode ser retirada no endereço da Federação. ', 'info'); break;
      case 5:
        Swal.fire('Concluído', 'Carteirinha entregue.', 'success'); break;
    }
  }

  function mensagemSucesso(msg, idInscricao) {
    Swal.fire('Sucesso', msg, 'success').then(() => {
      window.location.reload();
      // if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/evento/' + evento.id)
      // } else if ((valorAbsoluto + valorCategoria) === 0) {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + id)
      // } else {
      //   window.location.href = `/pagamento?e=${id}&i=${idInscricao}`
      // }

    })
  }

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Aguarde',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }

  async function mensagemConfirmacao(titulo, texto) {

    const result = await Swal.fire({
      title: titulo,
      text: texto,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (result.isConfirmed) {
      return true;
    } else if (result.isDismissed || result.isDenied) {
      return false;
    }
  }

  function mensagemErro(msg) {
    Swal.fire('Erro', msg, 'error')
  }




  async function cadastraImagem(idFiliacao, _imagem, idCarteirinha) {

    return new Promise((resolve, reject) => {
      let caminho = `federacoes/${idFederacao}/filiacoes/${idFiliacao}/pgtoCarteirinha/${idCarteirinha}`

      var uploadTask = firebase.storage().ref().child(caminho).put(_imagem, { contentType: _imagem.type });

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => { }, (error) => { }, async () => {
        try {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(downloadURL);

        } catch (error) {
          console.log('erro upload: ', error);
          reject();
        }

      });
    })


  }


  function converterData(data) {
    return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY HH:mm')
  }


  async function salvarPedido() {

    if(tipoCarteirinha === 'dig'){
      
      pedidoCarteirinhaDigital()

    }else{
      pedidoCarteirinhaFisica()
    }



  }

  function validacao() {


    // Validar endereço de entrega da carteirinha
    const opcional = '3';
    const envioAoFiliado = '2';

    if (federacao?.carteirinha?.opcaoEntrega == opcional) {
      if (!opcaoEntregaSelecionado) {
        mensagemAlerta('Por favor, selecione uma opção para entrega da carteirinha');
        return false;
      }
    }

    if (
      federacao?.carteirinha?.opcaoEntrega == envioAoFiliado ||
      (federacao?.carteirinha?.opcaoEntrega == opcional && opcaoEntregaSelecionado == envioAoFiliado)) {

      if (!endereco) {
        mensagemAlerta('Por favor, informe o endereço para envio de carteirinha ao filiado.');
        return false;
      }
      if (!numero) {
        mensagemAlerta('Por favor, informe o número residencial para envio de carteirinha ao filiado.');
        return false;
      }

      if (!cidade) {
        mensagemAlerta('Por favor, informe a cidade para envio de carteirinha ao filiado.');
        return false;
      }

      if (!uf) {
        mensagemAlerta('Por favor, informe a uf para envio de carteirinha ao filiado.');
        return false;
      }
      if (!cep) {
        mensagemAlerta('Por favor, informe a cep para envio de carteirinha ao filiado.');
        return false;
      }

    }


    if (solicitarComprovante()) {
      if (!uploadArquivoComprovante) {
        mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento');
        return false;
      }
    }


    const existeCarteirinhaPendente = carteirinhas.some(carteirinha => carteirinha.status !== 5)

    if (existeCarteirinhaPendente) {
      const careteirinhaPendente = carteirinhas.find(carteirinha => carteirinha.status !== 5)

      const documentacaoReprovada = 3;

      if (careteirinhaPendente.status !== documentacaoReprovada) {
        mensagemAlerta('Seu pedido de carteirinha já está sendo processado. Por favor, guarde um retorno da Federação.')
        return false;
      }
    }

    return true;

  }

  function validacaoCarteirinhaDigital() {

    if(!tipoCarteirinha == 'dig'){
      return;
    }

    const existeCarteirinhaPendente = carteirinhas.some(carteirinha => carteirinha.status !== 5)

    if (existeCarteirinhaPendente) {
      const careteirinhaPendente = carteirinhas.find(carteirinha => carteirinha.status !== 5)

      const documentacaoReprovada = 3;

      if (careteirinhaPendente.status !== documentacaoReprovada) {
        mensagemAlerta('Seu pedido de carteirinha já está sendo processado. Por favor, guarde um retorno da Federação.')
        return false;
      }
    }

    if (solicitarComprovante()) {
      if (!uploadArquivoComprovante) {
        mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento');
        return false;
      }
    }

    return true;

  }

  async function pedidoCarteirinhaFisica(){

    
    if (!validacao()) {
      return;
    }

    const existeCarteirinhaPendente = carteirinhas.some(carteirinha => carteirinha.status !== 5)

    const titulo = existeCarteirinhaPendente ? 'Salvar alterações?' : 'Confirmar pedido'
    const texto = existeCarteirinhaPendente ? 'Deseja salvar as alterações do pedido de carteirinha?' : 'Confirma solicitação para carteirinha?';


    if (!await mensagemConfirmacao(titulo, texto)) {
      return;
    }

    mensagemLoading(existeCarteirinhaPendente ? 'Salvando alterações...' : 'Salvando solicitação de carteirinha...');
    setLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    });


    if (existeCarteirinhaPendente) {
      if (carteirinhaReprovada) {

        let dados = {
          status: 1,
          dtAtualizado: firebase.firestore.Timestamp.fromDate(new Date()),
          observacao: ''
        }

        const enviarAoFiliado = '2';

        if (
          opcaoEntregaSelecionado === enviarAoFiliado ||
          federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado
        ) {
          dados = {
            ...dados,
            enderecoEntregaCarteirinha: {
              endereco: endereco,
              numero: numero,
              complemento: complemento,
              bairro: bairro,
              cidade: cidade,
              uf: uf,
              cep: cep
            }
          }
        }


        const careteirinhaPendente = carteirinhas.find(carteirinha => carteirinha.status !== 5)

        await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(careteirinhaPendente.id).update({
          status: 1,
          dtAtualizado: firebase.firestore.Timestamp.fromDate(new Date()),
          observacao: ''
        })

        if (solicitarComprovante()) {
          const urlPgtoCarteirinha = await cadastraImagem(idFiliacao, arquivoComprovante, careteirinhaPendente.id);

          dados = {
            ...dados,
            urlPgtoCarteirinha: urlPgtoCarteirinha
          }
        }

        await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaReprovada.id).update(dados)


        mensagemSucesso('Solicitação alterada com sucesso.')
      }



    } else {

      let dados = {
        status: 1,
        valor: valorTotal(),
        tipoCarteirinha: 'fisica',
        dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
        idFiliacao: idFiliacao,
        idFederacao: idFederacao,
        idFiliado: filiacao.idFiliado,
        urlAvatarFiliado: filiacao.urlAvatarFiliado,
        nomeFiliado: filiacao.nomeFiliado,
        cpfFiliado: filiacao.cpfFiliado,
        origemEmissao: 'individual'

      }

      if (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado) {
        dados = {
          ...dados,
          opcaoEntregaSelecionado: opcaoEntregaSelecionado
        }
      }

      const enviarAoFiliado = '2';

      if (
        opcaoEntregaSelecionado === enviarAoFiliado ||
        federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado
      ) {
        dados = {
          ...dados,
          enderecoEntregaCarteirinha: {
            endereco: endereco,
            numero: numero,
            complemento: complemento,
            bairro: bairro,
            cidade: cidade,
            uf: uf,
            cep: cep
          }
        }
      }

      const carteirinhaDocRef = await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').add(dados)

      const urlPgtoCarteirinha = await cadastraImagem(idFiliacao, arquivoComprovante, carteirinhaDocRef.id)

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaDocRef.id).update({
        id: carteirinhaDocRef.id,
        urlPgtoCarteirinha: urlPgtoCarteirinha
      })

      mensagemSucesso('Solicitação gerada com sucesso.')
    }
    
  }

  async function pedidoCarteirinhaDigital(){

    
    if (!validacaoCarteirinhaDigital()) {
      return;
    }

    const existeCarteirinhaPendente = carteirinhas.some(carteirinha => carteirinha.status !== 5)

    const titulo = existeCarteirinhaPendente ? 'Salvar alterações?' : 'Confirmar solicitação'
    const texto = existeCarteirinhaPendente ? 'Deseja salvar as alterações a solicitação de carteirinha digital?' : 'Confirma solicitação para carteirinha digital?';


    if (!await mensagemConfirmacao(titulo, texto)) {
      return;
    }

    mensagemLoading(existeCarteirinhaPendente ? 'Salvando alterações...' : 'Salvando solicitação de carteirinha...');
    setLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    });


    if (existeCarteirinhaPendente) {
      if (carteirinhaReprovada) {

        let dados = {
          status: 1,
          dtAtualizado: firebase.firestore.Timestamp.fromDate(new Date()),
          observacao: ''
        }

       
        const careteirinhaPendente = carteirinhas.find(carteirinha => carteirinha.status !== 5)

        await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(careteirinhaPendente.id).update({
          status: 1,
          dtAtualizado: firebase.firestore.Timestamp.fromDate(new Date()),
          observacao: ''
        })

        if (solicitarComprovante() && uploadArquivoComprovante) {
          const urlPgtoCarteirinha = await cadastraImagem(idFiliacao, arquivoComprovante, careteirinhaPendente.id);

          dados = {
            ...dados,
            urlPgtoCarteirinha: urlPgtoCarteirinha
          }
        }

        await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaReprovada.id).update(dados)

        mensagemSucesso('Solicitação alterada com sucesso.')
      }



    } else {

      let dados = {
        status: 1,
        valor: valorTotal(),
        tipoCarteirinha: 'digital',
        dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
        idFiliacao: idFiliacao,
        idFederacao: idFederacao,
        idFiliado: filiacao.idFiliado,
        urlAvatarFiliado: filiacao.urlAvatarFiliado,
        nomeFiliado: filiacao.nomeFiliado,
        cpfFiliado: filiacao.cpfFiliado,
        origemEmissao: 'individual'

      }

 
      const carteirinhaDocRef = await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').add(dados)

      const urlPgtoCarteirinha = await cadastraImagem(idFiliacao, arquivoComprovante, carteirinhaDocRef.id)

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaDocRef.id).update({
        id: carteirinhaDocRef.id,
        urlPgtoCarteirinha: urlPgtoCarteirinha
      })

      mensagemSucesso('Solicitação gerada com sucesso.')
    }
    

  }

  function solicitarComprovante() {

    if (carteirinhaReprovada && carteirinhaReprovada?.origemEmissao === 'filiacao') {
      return false;
    } else {
      return true;
    }


  }

  function mensagemStatus(status) {
    let mensagem = ''
    switch (status) {
      case 1: mensagem = 'Pendente'; break;
      case 2: mensagem = 'Em Produção'; break;
      case 3: mensagem = 'Reprovada'; break;
      case 4: mensagem = 'Retirada Disponível'; break;
      case 5: mensagem = tipoCarteirinha === 'dig' ? 'Habilitada' : 'Entregue'; break;
      default: mensagem = ''; break;
    }

    return mensagem;
  }

  function mensagemCor(status) {
    let cor = ''
    switch (status) {
      case 1: cor = 'orange'; break;
      case 2: case 4: cor = 'blue'; break;
      case 3: cor = 'red'; break;
      case 5: cor = 'green'; break;
      default: cor = ''; break;
    }
    return cor;
  }

  function valorTotal() {

    let valor = 0;

    if (tipoCarteirinha === 'dig') {

      if (federacao?.carteirinha?.tipoAquisicaoCarteirinhaDigital == '1') {
        valor += federacao?.carteirinha?.valorCarteirinhaDigital ? parseFloat(federacao?.carteirinha?.valorCarteirinhaDigital) : 0;
      }

    } else {

      valor += federacao?.carteirinha?.valorCarteirinha ? parseFloat(federacao?.carteirinha?.valorCarteirinha) : 0;

      if (cobrarTaxaEnvio()) {
        if (federacao?.carteirinha?.valorTaxaEnvio) {
          valor += parseFloat(federacao?.carteirinha?.valorTaxaEnvio);
        }
      }

    }


    return valor;

  }

  function valorCarteirinha() {
    let valor = 0;

    if (tipoCarteirinha === 'dig') {

      if (federacao?.carteirinha?.tipoAquisicaoCarteirinhaDigital == '1') {
        valor += federacao?.carteirinha?.valorCarteirinhaDigital ? parseFloat(federacao?.carteirinha?.valorCarteirinhaDigital) : 0;
      }

    } else {

      valor += federacao?.carteirinha?.valorCarteirinha ? parseFloat(federacao?.carteirinha?.valorCarteirinha) : 0;
    }

    return valor;

  }

  function cobrarTaxaEnvio() {

    // todo: incluir mecanismo para inclusão de carteirinha opcional
    const enviarAoFiliado = '2';
    const opcional = '3';
    const sim = '1';

    return (
      (
        federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado ||
        (federacao?.carteirinha?.opcaoEntrega == opcional && opcaoEntregaSelecionado == enviarAoFiliado)
      ) &&
      federacao?.carteirinha?.cobrarTaxaEnvio === sim
    )
  }

  function incluirCarteirinhaFisica() {
    const sim = '1';
    const opcional = '2';
    return (
      federacao?.carteirinha?.carteirinhaFisica == sim ||
      federacao?.carteirinha?.carteirinhaFisica == opcional
    )
  }

  function tipoAquisicaoOpcional() {
    return (incluirCarteirinhaFisica() &&
      federacao?.carteirinha?.opcaoEntrega === '3')
  }

  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Carteirinha{tipoCarteirinha == 'dig' ? ' digital' : ''}</h1>
            </div>
            <div class="headline__description">
              <span>Acompanhe aqui sua solicitação de carteirinha {tipoCarteirinha == 'dig' ? ' digital' : ''}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className='wpb_row row'>
                        <h6>Filiado: {filiacao.nomeFiliado} </h6>
                      </div>

                      <div className=' wpb_row row'>

                        <div class="wpb_column vc_column_container vc_col-sm-12">



                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div class="wpb_widgetised_column wpb_content_element">
                                <div class="wpb_wrapper">

                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div onClick={() => setNovaCarteirinha(!novaCarteirinha)} style={{ background: '#28a745', cursor: 'pointer', margin: '15px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill`}>
                                      <input style={{ cursor: 'pointer', fontSize: '16px', width: 'inherit' }} type="submit" value='Nova Carteirinha' class="wpcf7-form-control wpcf7-pnchr_submit" />
                                    </div>

                                    {/*<div class={`invisible`}></div>*/}

                                    <div onClick={() => { window.history.back() }} style={{ background: '#007bff', cursor: 'pointer', margin: '15px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-1`}>
                                      <button style={{ cursor: 'pointer', fontSize: '16px', width: 'max-content' }} type="submit" class="wpcf7-form-control wpcf7-pnchr_submit" ><i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar</button>
                                    </div>
                                  </div>


                                  {novaCarteirinha && (

                                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>
                                      {/* Tabela Extrato */}
                                      <table style={{ width: "100%", marginTop: 20 }}>
                                        <thead>
                                          <tr >
                                            <th class="schedule-table__day">Extrato</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <table style={{ width: "100%" }} className="3">
                                        <tbody>

                                          <tr>
                                            <td>Carteirinha{tipoCarteirinha === 'dig' ? ' Digital' : ''}</td>
                                            <td>{valorCarteirinha().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                          </tr>

                                          {(cobrarTaxaEnvio() && tipoCarteirinha === 'fis') && (
                                            <tr>
                                              <td>Taxa de Envio</td>
                                              <td>{parseFloat(federacao?.carteirinha?.valorTaxaEnvio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                            </tr>
                                          )}

                                          <tr>
                                            <td><b>Total a Pagar</b></td>
                                            <td><b>{valorTotal().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></td>
                                          </tr>

                                        </tbody>
                                      </table>
                                      {/* Tabela Extrato - Fim */}

                                      {/* Taxa Entrega Opcional - Início */}

                                      {tipoCarteirinha === 'fis' && (
                                        <>
                                        {tipoAquisicaoOpcional() && (
                                        <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px 14px', width: '100%', marginTop: '15px' }}>
                                          <FormControl component="fieldset" disabled={carteirinhaReprovada}>
                                            <h6>Selecione uma opção de recebimento da carteirinha</h6>
                                            <RadioGroup
                                              aria-label="options"
                                              name="options"
                                              value={opcaoEntregaSelecionado}
                                              onChange={(e) => setOpcaoEntregaSelecionado(e.target.value)}
                                              style={{ display: 'flex', flexDirection: 'row' }}
                                            >
                                              <FormControlLabel value="1" control={<Radio />} label="Retirar na Federação" />
                                              <FormControlLabel value="2" control={<Radio />} label="Enviar ao Filiado" />

                                            </RadioGroup>
                                          </FormControl>

                                          {opcaoEntregaSelecionado == '1' && (
                                            <div className='col-md-12' style={{ marginLeft: '80px' }}>

                                              <b>Endereço/Local de entrega da carteirinha: </b>{ReactHtmlParser(federacao?.carteirinha?.enderecoRetiradaCarteirinha)}

                                            </div>
                                          )}

                                          {opcaoEntregaSelecionado == '2' && (


                                            <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px', width: '100%', marginTop: '15px', marginBottom: '15px' }} className='vc_col-sm-12' >

                                              <p class="contact-form-name vc_col-sm-12">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Endereço de Entrega:</h6>
                                                </span>
                                              </p>

                                              <p class="contact-form-name vc_col-sm-4">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Endereço</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setEndereco(e.target.value)}
                                                    value={endereco} />
                                                </span>
                                              </p>

                                              <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>N°</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setNumero(e.target.value)}
                                                    value={numero} />
                                                </span>
                                              </p>

                                              <p class="contact-form-name vc_col-sm-3">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Complemento</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setComplemento(e.target.value)}
                                                    value={complemento} />
                                                </span>
                                              </p>

                                              <p class="contact-form-name vc_col-sm-3">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Bairro</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setBairro(e.target.value)}
                                                    value={bairro} />
                                                </span>
                                              </p>
                                              <p class="contact-form-name vc_col-sm-3">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Cidade</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setCidade(e.target.value)}
                                                    value={cidade} />
                                                </span>
                                              </p>
                                              <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>Estado</h6>
                                                  <select name="select" id="select" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-require" onChange={(e) => setUf(e.target.value)} >
                                                    <option value="">Escolha</option>
                                                    {UFs.map(_uf => (
                                                      <option value={_uf} selected={_uf === uf}>{_uf}</option>
                                                    )
                                                    )}
                                                  </select>

                                                </span>
                                              </p>



                                              <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                  <h6>CEP</h6>
                                                  <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={(e) => setCep(e.target.value)}
                                                    value={cep} />
                                                </span>
                                              </p>
                                            </div>
                                          )}


                                        </div>
                                      )}

                                      {(incluirCarteirinhaFisica() &&
                                        federacao?.carteirinha?.opcaoEntrega === '1') && (
                                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px 14px', width: '100%', marginTop: '15px' }}>

                                            <div className='col-md-12' style={{ marginLeft: '80px' }}>

                                              <b>Endereço/Local de entrega da carteirinha: </b>{ReactHtmlParser(federacao?.carteirinha?.enderecoRetiradaCarteirinha)}

                                            </div>
                                          </div>
                                        )}
                                        </>
                                      )}


                                      {/* Taxa Entrega Opcional - Fim */}




                                      {solicitarComprovante() ? (
                                        <>

                                          <p className='vc_col-md-12' style={{ marginTop: '15px' }}><b>*O pedido da carteirinha será processado após a confirmação do pagamento</b></p>

                                          {/* Tabela anexar arquivos - Inicio */}
                                          <table style={{ width: "100%", marginTop: 20 }}>
                                            <thead>
                                              <tr >
                                                <th class="schedule-table__day">Anexe abaixo o comprovante de pagamento da carteirinha</th>
                                              </tr>
                                            </thead>
                                          </table>

                                          {mediaQuery.matches ? (
                                            <table style={{ width: "100%" }} className="3">
                                              <tbody>

                                                <tr>
                                                  <td onClick={() => { refComprovanteFile.current.click() }}>
                                                    <div className="file-input-wrapper">
                                                      <input
                                                        ref={refComprovanteFile}
                                                        type="file"
                                                        id="file-input2"
                                                        className="file-input"
                                                        onChange={(e) => handleFotoComprovante(e)}
                                                      />
                                                      <label htmlFor="file-input" className="file-label" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', height: '36px', fontWeight: 'bold' }}>
                                                        Anexar
                                                      </label>
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                    </div>
                                                  </td>


                                                </tr>
                                                <tr>
                                                  <td>{'status: '}
                                                    {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                    {(uploadArquivoComprovante) && 'Anexado'}
                                                    {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                  </td>
                                                </tr>

                                                <tr style={{ display: 'flex', justifyContent: 'center' }}>
                                                  <td>
                                                    <div onClick={() => salvarPedido()} style={{ background: '#28a745', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill`}>
                                                      <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value={carteirinhaReprovada ? 'Salvar Alterações' : 'Confirmar Pedido'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                    </div>
                                                  </td>
                                                </tr>

                                              </tbody>
                                            </table>

                                          ) : (
                                            <table style={{ width: "100%" }} className="3">
                                              <thead style={{ height: '15px' }}>
                                                <tr style={{ background: '#121212', color: 'white' }}>
                                                  <th className="">Anexar </th>
                                                  <th className="" style={{ width: '250px' }}>Status </th>
                                                  <th className=""></th>
                                                </tr>
                                              </thead>
                                              <tbody>

                                                <tr>
                                                  <td onClick={() => { refComprovanteFile.current.click() }}>
                                                    <div className="file-input-wrapper">
                                                      <input
                                                        ref={refComprovanteFile}
                                                        type="file"
                                                        id="file-input2"
                                                        className="file-input"
                                                        onChange={(e) => handleFotoComprovante(e)}
                                                      />
                                                      <label htmlFor="file-input" className="file-label">
                                                        Selecione um arquivo
                                                      </label>
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                    {(uploadArquivoComprovante) && 'Anexado'}
                                                    {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div onClick={() => salvarPedido()} style={{ background: '#28a745', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12`}>
                                                      <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value={carteirinhaReprovada ? 'Salvar Alterações' : 'Confirmar Pedido'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                    </div>
                                                  </td>
                                                </tr>

                                              </tbody>
                                            </table>
                                          )}

                                        </>
                                      ) : (

                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px', justifyContent: 'center' }}>
                                          <div onClick={() => salvarPedido()} style={{ background: '#28a745', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', width: '220px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill`}>
                                            <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value='Salvar Alterações' class="wpcf7-form-control wpcf7-pnchr_submit" />
                                          </div>
                                        </div>
                                      )}


                                    </div>

                                  )}



                                  <div role="form" class="row " >



                                    {/* Tabela Extrato - Inicio */}
                                    {(true) && (
                                      <>
                                        <div id="ratings-widget-25" class="widget_ratings-widget clearfix vc_col-sm-12" style={{ textAlign: 'center', marginBottom: '10px', marginTop: '35px' }}>
                                          <h3>Histórico</h3>
                                        </div>
                                        <table style={{ width: "100%", marginTop: '0px' }}>
                                        </table>
                                        <Table style={{ width: "100%" }}>
                                          <Thead style={{ height: '15px' }}>
                                            <Tr style={{ background: '#121212', color: 'white' }}>
                                              <Th style={{ height: '15px' }} class="" >Data Solicitação </Th>
                                              <Th style={{ height: '15px' }} class="">Data Atualizado</Th>
                                              <Th style={{ height: '15px' }} class="">{tipoCarteirinha === 'dig' ? 'Data Habilitada' : 'Data Entregue'}</Th>
                                              {(!tipoCarteirinha === 'dig') && (
                                                <Th style={{ height: '15px' }} class="">Endereço</Th>
                                              )}
                                              <Th style={{ height: '15px' }} class="">Situação</Th>
                                              <Th style={{ height: '15px' }} class="">Observação</Th>
                                            </Tr>
                                          </Thead>
                                          <Tbody>
                                            {carteirinhas.sort((a, b) => b.dtPedido - a.dtPedido).map(carteirinha => (
                                              <Tr>
                                                <Td>{converterData(carteirinha.dtPedido)}</Td>
                                                {/* <Td>{carteirinha.valor > 0 ? carteirinha.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : '--'}</Td> */}
                                                {/* <Td>
                                                  {carteirinha.urlPgtoCarteirinha ? (
                                                    <a href={carteirinha.urlPgtoCarteirinha} target='_blank' style={{ textDecoration: 'underline', color: 'blue' }}>{'comprovante'}</a>
                                                  ) : '--'}

                                                </Td> */}
                                                <Td>{carteirinha.dtAtualizado ? converterData(carteirinha.dtAtualizado) : '--'}</Td>
                                                <Td>{carteirinha.dtEntregue ? converterData(carteirinha.dtEntregue) : '--'}</Td>

                                                {(!tipoCarteirinha === 'dig') && (
                                                  <Td>
                                                    <div
                                                      className={`badge-danger badge-doc-reprovada`}
                                                      onClick={() =>
                                                        mensagemEndereco(carteirinha)
                                                      } style={{ background: 'rgb(0, 123, 255)', cursor: 'pointer' }} >
                                                      <span className={`badge-danger badge-doc-reprovada`} style={{ background: 'rgb(0, 123, 255)' }}><i className='fa fa-tag'></i></span>
                                                    </div>
                                                  </Td>

                                                )}
                                                <Td>

                                                  <span style={{ fontWeight: 'bold', color: mensagemCor(carteirinha.status) }}>{
                                                    mensagemStatus(carteirinha.status)}</span>

                                                </Td>
                                                <Td>
                                                  <div
                                                    className={`badge-doc-reprovada badge-danger`}
                                                    style={{ cursor: 'pointer', background: mensagemCor(carteirinha.status) }}
                                                    onClick={() =>
                                                      mensagemObservacao(carteirinha.status, carteirinha.observacao)
                                                    } data-toggle="modal" data-target="#modal"
                                                  >
                                                    <span className={`badge-doc-reprovada badge-danger`} style={{ background: mensagemCor(carteirinha.status) }}><i className='fa fa-commenting'></i></span>
                                                  </div>
                                                </Td>


                                              </Tr>
                                            ))}

                                          </Tbody>
                                        </Table>
                                      </>
                                    )}

                                    {carteirinhas.length === 0 &&
                                      (<p style={{ textAlign: 'center', marginTop: '20px' }} >Nenhuma Pedido de Carteirinha Gerado</p>)
                                    }

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
    </div>
  );
}
